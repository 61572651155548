
/*旋转木马样式*/
.wrap{width: 100%;height: 100%}
.poster-main{ position:relative;width:800px;height:270px;overflow: hidden;margin:0 auto;}
.poster-list{width:800px;height:270px;position: relative;}
.poster-item{ position:absolute;overflow: hidden;}
.poster-item:hover .title-wrap{bottom: 0;}
.poster-btn{ position:absolute;top:0;width:100px;height:270px; z-index:100; cursor:pointer;  opacity:0.8}
.btn-left{position: absolute;width: 31px;height: 86px;bottom: 0;left: 0;/*background: url(../image/btn-left.png) no-repeat;*/z-index: 100;cursor: pointer;}
.btn-right{position:absolute;width:31px; height:86px; bottom: 0;right:0; /*background:url(../image/btn-right.png) no-repeat;*/z-index:100;cursor: pointer;}
.poster-prev-btn{composes: poster-btn;left:0; background: url(../image/btn_l.png) no-repeat center center;}
.poster-next-btn{composes: poster-btn;right:0; background: url(../image/btn_r.png) no-repeat center center;}
/*指示器样式*/
.dots {display: inline-block;width: 10px;height: 10px;background-color: #fff;border-radius: 5px;margin: 0 5px;cursor: pointer;opacity: 0.2;}
.active {composes: dots;/*继承dots*/background-color: #fff;opacity: 1}
.dots-wrap {overflow: hidden;position: absolute;z-index: 99999;bottom: 0px;left: 50%;margin-left: -60px;}
.title-wrap{position: absolute;left: 0;bottom: -70px;width:100%;height: 49px;font-size:24px;font-weight:bold;background:rgba(0,0,0,0.4);text-align: center;z-index: 999999;transition: bottom .2s ease-in;pointer-events: none;/*css3属性，IE11.0+支持*/}
.title-wrap-iner{display:table-cell;/*vertical-align:middle;*/width: 100%;height: 100%;}
.title-txt{color:#fff;font-size: 18px;padding: 0 10px}






