@charset "utf-8";
.layout{
    z-index: auto;
}
a{
    color: #ffffff!important;
}
.header{
    left:50%;
    width:100%;
    height:60px;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    z-index: 100;
    background-color: rgba(0,0,0,0.2);
}
.logo{
    float:left;
    height: 34px;
    width:168px;
    margin-left:10%;

}
.logoimg{
    cursor: pointer;
    width:100%;
    height: 34px;
    position: relative;
    top:20px
}
.logo_title {
    width: 85%;
}
