.logo_wenzi{
    font-size: 20px;
    color: #ffffff;
}
.header{
    /*background-image: url("../image/topbg.png") !important;
    height:84px!important;*/
}
.menu{
    top:0;
    width:65%;
    height:72px;
    background-color: #001529;
    color:#ffffff;
    font-size:16px;
    float: left;
    padding-top:0;
    border-bottom: 0px !important;
    background-color: rgba(0,0,0,0);
    padding-left:10%;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
    margin-right: 10%!important;
    margin-bottom: 20px!important;
    height: 72px!important;
    width:20%!important;
    text-align: center;
}
.ant-menu-item-selected {
    /* color: #1890ff; */
    color:#ffffff !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color:#ffffff!important;
    border-bottom: 0px solid #ffffff!important;
    background-color:rgba(85, 85, 85, 0.250980392156863) !important ;
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {

    border-bottom: 0px solid transparent !important;
}

.video-js{
    width:100%!important;
    height:100%!important;
}
.main-wrap{
    width:100%;
    height:85%
}
.logo2{
    position: absolute;
    width: 168px;
    height: 34px;
    top: 15%;
    left: 8%;
    z-index: 999;
}
.video{
    width:100%;
    z-index: 500;
}
.middle{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: relative;
    width: 100%;
    /*height: 100%;
    overflow-y:scroll;*/
}
.middle-wrapper{
    top: 50%;
    left: 50%;
    transform: translate(-60%,-60%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.leftnn{
    padding-top:35px;
    float:left;
}
.rightnn{
    padding-top:35px;
}

.phoneimg{
    position: absolute;
    width: 256px;
    height: 516px;
    background-image: url(../image/phoneimg.png);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius:25px 25px 25px 25px;
    z-index:888;
}
.mycloass{
    height: 812px;
    width: 1281px;
}
.myvideo{
    position: absolute;
    border-radius: 16px;
    height: 483px;
    width:100%;
    object-fit: fill;
    left: 13px;
    top:22px;
}

/*#video1{*/
/*    display:block;*/
/*}*/
/*#video2{*/
/*    display:none;*/
/*}*/
/*#video3{*/
/*    display:none;*/
/*}*/
/*#video4{*/
/*    display:none;*/
/*}*/
/*#video5{*/
/*    display:none;*/
/*}*/
.appbootm{
    position: absolute;
    float: left;
    height: 48px;
    width: 230px;
    left: 9px;
    bottom: 43px;
    /*background: url(../image/phonedi.png) no-repeat;*/
    background-size:100% 100%;
    border-radius: 0 0 15px 15px;
    top: 452px;
    z-index: 900;
}
.appmenu{
    width: 20%;
    height:35px;
    float: left;
}
.lunbos{
    background-color:#373737;
    height:112px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    /*bottom:0px;*/
    transform: translateX(-50%);
    /*width: 73.5%;
    left: 47.7%;*/
    width:1411px;
    left:705px;
     /*top:854px;*/
    top:717px;
}
.lunbos::-webkit-scrollbar {
    display: none;
}
::-webkit-scrollbar-thumb{
    background-color: red;
    border-radius: 5px;
}
.lunbos::-webkit-scrollbar-track{

 }
.lunbos::-webkit-scrollbar-button{

 }
.lunbos::-webkit-scrollbar-track-piece{
 }
.appmenu11s{
    /*height:25px;*/
    /*width:25px;*/
    /*background: url("../image/homeafter.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 21px;
    margin-top: 3px;
    display: inline-block;

}
.appmenu2s {
    float: left;
    /*height: 25px;*/
    /*width: 25px;*/
    /*background: url("../image/zhibo.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 16px;
    margin-top: 3px;
    display: inline-block;
}
.appmenu21s{
    /*height:25px;*/
    /*width:25px;*/
    /*background: url("../image/zhiboafter.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 16px;
    margin-top: 3px;
    display: inline-block;
}
.appmenu3s {
    float: left;
    /*height: 25px;*/
    /*width: 25px;*/
    /*background: url("../image/zmdss.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 9px;
    margin-top: 3px;
    display: inline-block;
}
.appmenu31s{
    /*height:25px;*/
    /*width:25px;*/
    /*background: url("../image/zmdssafter.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 9px;
    margin-top: 3px;
    display: inline-block;
}
.appmenu4s {
    float: left;
    /*height: 25px;*/
    /*width: 25px;*/
    /*background: url("../image/huodong.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 6px;
    margin-top: 3px;
    display: inline-block;
}
.appmenu41s{
    /*height:25px;*/
    /*width:25px;*/
    /*background: url("../image/huodongafter.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 6px;
    margin-top: 3px;
    display: inline-block;
}
.appmenu5s {
    float: left;
    /*height: 25px;*/
    /*width: 25px;*/
    /*background: url("../image/my.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 2px;
    margin-top: 3px;
    display: inline-block;
}
.appmenu51s{
    /*height:25px;*/
    /*width:25px;*/
    /*background: url("../image/myafter.png");*/
    background: url("../image/videopg.png");
    background-size: 100% 100%;
    margin-left: 2px;
    margin-top: 3px;
    display: inline-block;
}
.appmenu img{
    padding-left:18px;
    height:30px;
}
.guangshu{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 27%;
    left: 42%;
    height: 465px;
    width: 170px;
    background: url("../image/guangshu.png");
    background-size: 100% 100%;
    transition: opacity 10s;
    z-index: 400;
}


.righttitle{
    height:95px;
    white-space: nowrap;
    color: #ec808d;
    font-size: 30px;
    font-weight: 500;
    margin: 13px 0 0;
}
.bofangng{
    z-index: 999;
    padding-top:15% !important;
    padding-left:40%;
}
.leftplayer{
    width: 83%;
    padding-left: 5px;
}
.onimg{
    /* width:90%;
    margin-left:5%; */
}
.androids{
    width: 100%;
    background: url(../image/xiaochengxuma.png);
    z-index: 600;
}
.ios{
    width: 100%;
    height:33px;
    background: url(../image/iostitle.png);
    z-index: 600;
}
.iosmap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 33px;
    border-radius: 50px 50px 50px 50px;
    background: url("../image/codeback.png");
    cursor: pointer;
    position: relative;
    margin-left: 30px;
}
.iosmap:hover .ioscodeimg{
    display:block;
}
.xcx{
    width: 100%;
    height:33px;
    background: url(../image/xcxtitle.png);
    z-index: 600;
}
.xcxmap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 33px;
    border-radius: 60px 60px 60px 60px;
    background: url("../image/codeback.png");
    margin-left: 30px;
    cursor: pointer;
    position: relative;
}
.xcxmap:hover .xcxcodeimg{
    display:block;
}
.codeimg{
    content: "";
    position: absolute;
    top: 18px;
    width: 117px;
    height: 126px;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0px 0px 10px 10px;
    background: url(../image/codeback.png);
    text-align: center;
    z-index: 500;
    display:none;
    padding-left:7px;
}
.codeimg img{
    width:100px;
    height:100px;
    margin-top:15px;
}
.ioscodeimg{
    content: "";
    position: absolute;
    top: 18px;
    width: 117px;
    height: 126px;
    background-repeat: no-repeat;
    background-size: contain;
    background: url(../image/codeback.png);
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    display: none;
    z-index: 500;
    padding-left:7px;
}
.ioscodeimg img{
    width:100px;
    height:100px;
    margin-top:15px;
}
.xcxcodeimg{
    content: "";
    position: absolute;
    top: 18px;
    width: 117px;
    height: 126px;
    background-repeat: no-repeat;
    background-size: contain;
    background: url(../image/codeback.png);
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    display: none;
    z-index: 500;
    padding-left:7px;
}
.xcxcodeimg img{
    width:100px;
    height:100px;
    margin-top:15px;
}
.totalcontent{
    background-color: #373737;
}

.footer{
    color:#fff;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.footer p{
    margin-bottom: 0px!important;
}
.foottop{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
.yqlj{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
}
.list{
    width: 1080px;
    margin: 0;
    padding: 0 80px 0 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    position: relative;
}
.vjs-tech{
    width:100% !important;
    height:100% !important;
}
a{
    color:#fff;
}
.feet{
    text-align: center;
}
.feet p{
    color: #ffffff;
}
.feet p img{
    height: 19px;
    padding-left: 5px;

}
.bootom{
    position: relative;
    bottom: 0px;
    width: 1080px;
    color: #ffffff;
    text-align: center;
}
.ant-layout-footer {
    padding: 24px 50px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: rgba(0,0,0,0) !important;
}

.footerimg{
    height:40px;
    bottom:2px;
    padding-top: 24px;

}
.footerimg img{
    height:40px;
    padding-right: 5px;
    padding-bottom: 10px;
}
/*2023-03-1*/
.content{
    position:relative;
}
.banner{
	position:relative;
	width:100%;
	overflow-x:hidden;
	text-align: center;
    height:720px;
}
.banner .banner-img{
    width:100%;
	margin:0 auto;
}
.banner .banner-bg{
	position:absolute;
	bottom:0;
	width:100%;
	left:0;
}
.middle{background:#F7F7F7}
.middle-con{
	width:1504px;
	height:746px;
	margin:0 auto;
	background:#fff;
	padding:17px 0 17px 19px;
	position:relative;
}
.middle-video{
	width:1080px;
	height:608px;
}
.dvideos{
	width:1080px;
	height:708px;
	position: absolute;
	top:17px;
	overflow: hidden;
}
.onimg{
	width:1080px;
	height:608px;
}
.zhongs{
    top: 0px;
   /* left: 45%;
    transform: translatex(-55%);*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* height: 100%; */
    /* max-height: 77%;
    width: 1139px; */

}
.phones{
	width:1080px;
	height:708px;
	display: inline-block;
	transition: opacity 4s;
	float:left;
}
.contenttitle{
    height:100px;
    background-color:#434343;
    font-size:30px;
	line-height:30px;
    color:#ffff;
    padding:21px 29px;
}
.title-name{
	height:30px;
	display: -webkit-box;
	line-height: 35rpx;
	word-break: break-all;
	-webkit-box-orient: vertical;
	-webkit-line-clamp:1;
	overflow: hidden;
	text-overflow:ellipsis;
}
.title-time{
	font-size:18px;
	color:#B2B2B2;
	margin-top:14px;
}
.rightcontents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color:#fff;
	float:left;
	width:390px;
	padding-left:12px;
	color:#000;
	position:relative;
}
.qrcodes{

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
	position:absolute;
	top:17px;
	left:0;
}
.right-title{
	font-size:24px;
	color:#CD1717;
	padding-top:9px;
	text-align: center;
	letter-spacing: 10px;
}
.appmenu1s{
    float:left;
    display: inline-block;
    text-align: center;
    width:355px;
    height:91px;
	margin-bottom:15px;
}
.androidmaps {
	height:660px;
    padding-top:18px;
    padding-left:10px;
    padding-right:10px;
    overflow-y: scroll;
}
.androidmaps img{
   /* padding-top:5px;
    padding-bottom: 9px; */
    margin: 0 auto;

}
.leftnnd{
    width:160px;
	height:91px;
	float:left;
}
.leftnnd img{
   width:160px;
   height:91px;

}
.rightnnd{
	width:182px;
	float:left;
	margin-left:12px;
	text-align:left;
}
.lbtitle{
   margin:0 auto;
   color:#000;
   font-size:20px;
   line-height:30px;
   height:60px;
   word-break: break-all;
   -webkit-box-orient: vertical;
   -webkit-line-clamp:2;
   overflow: hidden;
   text-overflow:ellipsis;
}
.lbtime{
	margin-top:12px;
	padding-left:20px;
	font-size:16px;
	color:#B5B5B5;
	background:url("../image/list5.png") center left no-repeat;
}
.list-middle{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: relative;
	width: 100%;
	/*height: 100%;
	overflow-y:scroll;*/
}
.dibu{
	width:100%;
	overflow-x: hidden;
	padding-top:500px;
	text-align: center;
}
.dibu img{
	margin:0 auto;
}
.logoimg{
	top:5px !important;
}
.ant-menu-title-content a{
	font-size:30px;
}
