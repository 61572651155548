
.bgtitle{
    width:100%;
    text-align: center;
}
.bgtitle img{
    margin: 0 auto;
}
.description{
    margin-top: 90px;
}
.aboutcount{
    margin-top: 62px;
    width:100%;
    font-size: 24px;
    line-height: 54px;
    font-family:"微软雅黑";
}
.aboutcount div{
    width: 1200px;
    margin:0 auto;
}
.footertop{
    width: 100%;
}
.bootomss{
    background-color: #DB5A5F ;
    padding-top:55px;
    padding-bottom: 30px;
}
.beianwz{
    padding-left:26%;
    float:left
}

.ant-carousel .slick-dots li{
	width:16px !important;
	height:16px !important;
	border-radius:16px !important;
	background:rgba(0,0,0,0.5) !important;
}
.ant-carousel .slick-dots li button{
	width:16px !important;
	height:16px !important;
	border-radius:16px !important;
	background:rgba(0,0,0,0.5) !important;
}

.fenlei1{
	width:100%;
	margin-top:104px;
}
.fenlei1-list{
	width:1342px;
	margin:0 auto;
	position:relative;
	padding:0;
}
.fenlei1-item{
	width:200px;
	height:360px;
	list-style: none;
	background:url("../image/zhuanti8.png") center top no-repeat;
	display: inline-block;
	margin:0 11px;
	cursor:pointer;
}
.fenlei1-img{
	width: 100%;
}
.fenlei1-name{
	font-size:26px;
	color:#585858;
	writing-mode: tb-rl;
	margin:0 auto;
	margin-top:22px;
	letter-spacing: 2px;
}
.lunbo{
    width:100%
}
.ant-carousel .slick-slide img{
    width:auto !important;
}
.slick-list{
	width:1920px;
	margin:0 auto !important;
}
.fenlei2{
	width:100%;
	margin-top:60px;
}
.fenlei2-list{
	width:1340px;
	margin:0 auto;
	position:relative;
	padding:0;
}
.fenlei2-item{
	width:428px;
	height:240px;
	list-style: none;
	display: inline-block;
	margin:0 9px;
	cursor:pointer;
}
.fenlei2-img{
	width: 100%;
}
.zhuantidiv{
	width:100%;
	overflow-x: hidden;
}
.zhuanti-dibu{
	width:100%;
	text-align: center;
	margin-top:109px;
}
.zhuanti-dibu img{
	margin:0 auto;
}
.jingcai-zhibo{
	margin:50px 0 60px 0;
	with:100%;
	text-align: center;
	position: relative;
}
.jingcai-title{
	color:#585858;
	font-size:36px;
	font-weight: bold;
	letter-spacing: 2.5px;
	display: inline-block;
}
.jingcai-more{
	font-size:24px;
	color:#585858;
	font-weight: bold;
	display: inline-block;
	letter-spacing: 1.5px;
	position:absolute;
	left:50%;
	margin-left:518px;
	bottom:2px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


