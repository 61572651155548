
.bgtitle{
    width:100%;
    text-align: center;
}
.bgtitle img{
    margin: 0 auto;
}
.description{
    margin-top: 90px;
}
.aboutcount{
    margin-top: 62px;
    width:100%;
    font-size: 24px;
    line-height: 54px;
    font-family:"微软雅黑";
}
.aboutcount div{
    width: 1200px;
    margin:0 auto;
}
.develop{
    padding-top:99px;
    width:100%;
}
.lunbo{
    width:100%
}
.ant-carousel .slick-slide img{
    width:100% !important;
}
.fzlc{
    width: 59%;
    height:500px;
    background: url("../image/timews.png");
    background-repeat:no-repeat;
    position:relative;

}
.developcion{
    position: relative;
    padding-top: 50px;
    margin-left: 0!important;
    left:0px;
}

.fzlcs{
    padding-left: 146px;
    padding-right: 30px;
    float:left;
}
.developcion2{
    position: relative;
    margin-top: 29fzlc0px;
    left:0px;
    margin-left: 0!important;
    top:200px;
    z-index: 999;
}
.fzlcs2{
    padding-left: 37px;
    padding-right: 126px;
    top:206px;
    float:left;
    z-index: 999;
}
.lastcoin{
    padding-right: 0px!important;
}
.flcimg{
    margin-top: 120px;
    position: absolute;
    left:0px;
}
.contact{
    padding-top: 148px;
}
.lxwm{
    width:100%;
    text-align: center;
}
.footertop{
    width: 100%;
}
.bootomss{
    background-color: #DB5A5F;
    padding-top:55px;
    padding-bottom: 30px;
}
.beianwz{
    padding-left:26%;
    float:left
}
