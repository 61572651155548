.logo_wenzi{
    font-size: 20px;
    color: #ffffff;
}
.menu{
    top:0;
    width:80%;
    height:60px;
    background-color: #001529;
    color:#ffffff;
    font-size:16px;
    float: right;
    padding-top:0;
    border-bottom: 0px !important;
    background-color: rgba(0,0,0,0);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
    margin-right: 20%!important;
    margin-bottom: 20px!important;
    height: 60px!important;
    width:10%!important;
    text-align: center;
}
.ant-menu-item-selected {
    /* color: #1890ff; */
    color:#ffffff !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color:#ffffff!important;
    border-bottom: 0px solid #ffffff!important;
    background-color:rgba(85, 85, 85, 0.250980392156863) !important ;
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {

   border-bottom: 0px solid transparent !important;
}
.content{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.logo2{
    position: absolute;
    width: 168px;
    height: 34px;
    top: 15%;
    left: 8%;
    z-index: 999;
}
.video{
    width:100%;
    z-index: 500;
}
.middle{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: relative;
    width: 100%;
    height: 100%;
}
.middle-wrapper{
    top: 50%;
    left: 50%;
    transform: translate(-60%,-60%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.zhong{
    top: 57%;
    left: 50%;
    transform: translate(-60%,-60%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    width:690px;
    height:100%;
}
.phone{
    position: absolute;
    top:25%;
    left:0;
    transition: opacity 4s;
    z-index: 500;
}
.phoneimg{
    position: absolute;
    width: 256px;
    height: 516px;
    background-image: url(../image/phoneimg.png);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius:25px 25px 25px 25px;
    z-index:888;
}
.dvideo{
    position: absolute;
    width: 235px;
    height: 483px;
    top:5%;
    overflow: hidden;
}
.myvideo{
    position: absolute;
    border-radius: 16px;
    height: 483px;
    width:100%;
    object-fit: fill;
    left: 13px;
    top:22px;
}
/*#video1{*/
/*    display:block;*/
/*}*/
/*#video2{*/
/*    display:none;*/
/*}*/
/*#video3{*/
/*    display:none;*/
/*}*/
/*#video4{*/
/*    display:none;*/
/*}*/
/*#video5{*/
/*    display:none;*/
/*}*/
.appbootm{
    position: absolute;
    float: left;
    height: 48px;
    width: 230px;
    left: 9px;
    bottom: 43px;
    /*background: url(../image/phonedi.png) no-repeat;*/
    background-size:100% 100%;
    border-radius: 0 0 15px 15px;
    top: 452px;
    z-index: 900;
}
.appmenu{
    width: 20%;
    height:35px;
    float: left;
}
.appmenu1{

    float:left;
    height:25px;
    top: 443px;
    z-index: 900;
    width:25px;
    background: url("../image/home.png");
    background-size: 100% 100%;
    margin-left: 21px;
    margin-top: 3px;
}
.appmenu11{
    height:25px;
    width:25px;
    background: url("../image/homeafter.png");
    background-size: 100% 100%;
    margin-left: 21px;
    margin-top: 3px;
}
.appmenu2 {
    float: left;
    height: 25px;
    width: 25px;
    background: url("../image/zhibo.png");
    background-size: 100% 100%;
    margin-left: 16px;
    margin-top: 3px;
}
.appmenu21{
    height:25px;
    width:25px;
    background: url("../image/zhiboafter.png");
    background-size: 100% 100%;
    margin-left: 16px;
    margin-top: 3px;
}
.appmenu3 {
    float: left;
    height: 25px;
    width: 25px;
    background: url("../image/zmdss.png");
    background-size: 100% 100%;
    margin-left: 9px;
    margin-top: 3px;
}
.appmenu31{
    height:25px;
    width:25px;
    background: url("../image/zmdssafter.png");
    background-size: 100% 100%;
    margin-left: 9px;
    margin-top: 3px;
}
.appmenu4 {
    float: left;
    height: 25px;
    width: 25px;
    background: url("../image/huodong.png");
    background-size: 100% 100%;
    margin-left: 6px;
    margin-top: 3px;
}
.appmenu41{
    height:25px;
    width:25px;
    background: url("../image/huodongafter.png");
    background-size: 100% 100%;
    margin-left: 6px;
    margin-top: 3px;
}
.appmenu5 {
    float: left;
    height: 25px;
    width: 25px;
    background: url("../image/my.png");
    background-size: 100% 100%;
    margin-left: 2px;
    margin-top: 3px;
}
.appmenu51{
    height:25px;
    width:25px;
    background: url("../image/myafter.png");
    background-size: 100% 100%;
    margin-left: 2px;
    margin-top: 3px;
}
.appmenu img{
    padding-left:18px;
    height:30px;
}
.rightcontent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top:38%;
    left:46%;
}
.guangshu{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 27%;
    left: 42%;
    height: 465px;
    width: 170px;
    background: url("../image/guangshu.png");
    background-size: 100% 100%;
    transition: opacity 10s;
    z-index: 400;
}


.righttitle{
    height:95px;
    white-space: nowrap;
    color: #ec808d;
    font-size: 30px;
    font-weight: 500;
    margin: 13px 0 0;
}
.qrcode{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 52px;
    padding-left: 14px;

}
.androidss{
    width: 100%;
    height:33px;
    background: url(../image/androidtitle.png);
    z-index: 600;
}
.androidmap {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 33px;
    border-radius: 60px 60px 60px 60px;
    background: url("../image/codeback.png");
    margin-right: 15px;
    cursor: pointer;
    position: relative;
}
.androidmap:hover .codeimg{
    display:block;
}
.ios{
    width: 100%;
    height:33px;
    background: url(../image/iostitle.png);
    z-index: 600;
}
.iosmap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 33px;
    border-radius: 50px 50px 50px 50px;
    background: url("../image/codeback.png");
    cursor: pointer;
    position: relative;
    margin-left: 30px;
}
.iosmap:hover .ioscodeimg{
    display:block;
}
.xcx{
    width: 100%;
    height:33px;
    background: url(../image/xcxtitle.png);
    z-index: 600;
}
.xcxmap {
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
    width: 117px;
    height: 33px;
     border-radius: 60px 60px 60px 60px;
    background: url("../image/codeback.png");
     margin-left: 30px;
     cursor: pointer;
     position: relative;
 }
.xcxmap:hover .xcxcodeimg{
    display:block;
}
.codeimg{
    content: "";
    position: absolute;
    top: 18px;
    width: 117px;
    height: 126px;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0px 0px 10px 10px;
    background: url(../image/codeback.png);
    text-align: center;
    z-index: 500;
    display:none;
    padding-left:7px;
}
.codeimg img{
    width:100px;
    height:100px;
    margin-top:15px;
}
.ioscodeimg{
    content: "";
    position: absolute;
    top: 18px;
    width: 117px;
    height: 126px;
    background-repeat: no-repeat;
    background-size: contain;
    background: url(../image/codeback.png);
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    display: none;
    z-index: 500;
    padding-left:7px;
}
.ioscodeimg img{
    width:100px;
    height:100px;
    margin-top:15px;
}
.xcxcodeimg{
    content: "";
    position: absolute;
    top: 18px;
    width: 117px;
    height: 126px;
    background-repeat: no-repeat;
    background-size: contain;
    background: url(../image/codeback.png);
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    display: none;
    z-index: 500;
    padding-left:7px;
}
.xcxcodeimg img{
    width:100px;
    height:100px;
    margin-top:15px;
}
.footer{
    color:#fff;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.footer p{
    margin-bottom: 0px!important;
}
.foottop{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
.yqlj{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
}
.list{
    width: 1080px;
    margin: 0;
    padding: 0 80px 0 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    position: relative;
}

 a{
    color:#fff;
}
.feet{
    text-align: center;
}
.feet p{
    color: #ffffff;
}
.feet p img{
    height: 19px;
    padding-left: 5px;

}
.bootom{
    position: relative;
    bottom: 0px;
    width: 1080px;
    color: #ffffff;
    text-align: center;
}
.ant-layout-footer {
    padding: 24px 50px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: rgba(0,0,0,0) !important;
}

.footerimg{
    height:40px;
    bottom:2px;
    padding-top: 24px;

}
.footerimg img{
    height:40px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.header{
	background-image:none !important;
	height:73px !important;
	background:rgba(0,0,0,0.2) !important;
	
}